<template>
  <div class="base-box">
    <UploadInfoCom 
      :options="options"
      @changeDataSource="changeDataSource"
      @uploadSuccess="uploadSuccessCallBack"
      />
    <SearchInput
      :placeHolder="placeHolder"
      @searchInput="searchInput"
      @removeFile="removeFile"
      @cancelFileSync="cancelFileSync"
      />
    <SwitchTable 
      @changeAsyncType="changeAsyncType"
      :asyncInfo="asyncInfo"
      />
    <TableList
      v-if="showCom"
      v-show="!isShowZip"
      :dataList="dataList"
      :total="total"
      :listType="listType"
      :pageNum="pageNum"
      :pageSize="pageSize"
      @operate="operate"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
      />
    <div v-show="isShowZip" class="zip-upload-box">
      <i></i>
      <p v-if="listType == '1'">附件压缩包同步成功</p>
      <p v-if="listType == '0' && uploadStatus == 'success'">附件压缩包上传成功</p>
    </div>
  </div>
</template>

<script>
import dataMoveMixin from '@/api/dataMoveMixin'
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
import SwitchTable from '@/components/UploadInfoCom/SwitchTable.vue'
export default {
  name: "attachFile",
  mixins: [dataMoveMixin],
  data() {
    return{
      placeHolder: '请输入合同名称、附件名称关键词进行搜索',
      isShowZip: false,
      asyncInfo: {}, //切换组件数据
      isDisabled: false, // 按钮置灰
      listType: '0',
      showCom: true,
      dataList: [], // 列表数据
      total: 0, // 分页数据
      pageSize: 10, // 页面数据条数
      pageNum: 1, // 页数
      searchInfo: '', // 搜索内容
      deleteCount: 0, // 删除数量
      cancelCount: 0, // 取消数量
      uploadStatus: '',
      loading: null,
      options: [{
        value: 'COST',
        label: '老成本'
      }, {
        value: 'BILLS',
        label: '旧工联单'
      }, {
        value: 'NEW_BILLS',
        label: '新工联单'
      }, {
        label: '投管工联单',
        value: 'SHTZ_BILLS'
      }]
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput,
    SwitchTable
  },
  methods: {
    // 全部删除
    removeFile() {
      if (this.listType !== '0') {
        this.$message.warning('请在未同步列表下点击')
        return
      }
      this.loading = this.$loading({ 
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: "正在删除当前页..."
      })
      this.dataList.forEach(item => {
        this.deleteRowMessage(item, 'all')
      })
    },
    // 全部取消同步
    cancelFileSync() {
      if (this.listType !== '1') {
        this.$message.warning('请在已同步列表下点击')
        return
      }
      this.loading = this.$loading({ 
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: "正在取消同步当前页..."
      })
      this.dataList.forEach(item => {
        this.cancelSyn(item, 'all')
      })
    },
    // new-------------------------------
    changeDataSource(val) {
      if (val === 'BILLS' || val === 'NEW_BILLS') {
        this.isShowZip = true
      } else {
        this.isShowZip = false
      }
    },
    // 上传成功回调
    uploadSuccessCallBack() {
      this.uploadStatus = 'success'
      this.init()
    },
    // 切换列表
    changeAsyncType(val) {
      this.showCom = false
      if (val === 'Y') {
        this.listType = '1'
      } else {
        this.listType = '0'
      }
      this.init()
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      this.getAttachFileInformation()
    },
    // 分页-却换pageSize
    pageSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    // 搜索
    searchInput(val) {
      this.searchInfo = val
      this.init()
    },
    // 事件选择
    operate(type, val) {
      if (type === 'cancel') {
        this.cancelSyn(val)
      } else {
        this.deleteRowMessage(val)
      }
    },
    // 删除
    deleteRowMessage(val, type) {
      this.$request.post(this.URL.deleteAttachFile + '?fileId=' + val.id).then(res => {
        if (res.code && res.code === '200') {
          if (type && type === 'all') {
            this.deleteCount++
          } else {
            this.init()
          }
        }
      }).catch(res => {
        if(this.loading) {
          this.loading.close()
        }
        this.$message.error(res.message)
      })
    },
    // 取消同步
    cancelSyn(val, type) {
      this.$request.post(this.URL.unSyncAttachFile + '?fileId=' + val.id).then(res => {
        if (res.code === '200') {
          if (type && type === 'all') {
            this.cancelCount++
          } else {
            this.$message.success(res.data.result)
            this.init()
          }
        }
      }).catch(() =>{
        // 关闭全局loading
        if(this.loading) {
          this.loading.close()
        }
      })
    },
    // 查询列表数据
    getAttachFileInformation() {
      const params = {
        'syncStatus': this.listType,
        "searchInfo": this.searchInfo,
        "pageSize": this.pageSize,
        "pageNum": this.pageNum
      }
      this.$request.post(this.URL.attachFileList, params).then(res => {
        this.showCom = true
        if (res.code && res.code === '200') {
          // res.data.pageInfo.list.forEach(item => {
          //   item.createDate = item.createDate.split('T')[0]
          // })
          // 表格
          this.dataList = res.data.pageInfo.list
          // 分页
          this.pageNum = res.data.pageInfo.pageNum
          this.pageSize = res.data.pageInfo.pageSize
          this.total = parseInt(res.data.pageInfo.total)
          // 切换按钮
          if (this.listType === '0') {
            this.asyncInfo = {
              unsynchronized: res.data.pageInfo.total,
              synchronization: res.data.count - res.data.pageInfo.total
            }
          } else {
            this.asyncInfo = {
              unsynchronized: res.data.count - res.data.pageInfo.total,
              synchronization: res.data.pageInfo.total
            }
          }
          if (parseInt(this.asyncInfo.unsynchronized) === 0) {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        }
      }).catch(res => {
        this.$message.error(res.message)
      })
    },
    init() {
      this.pageNum = 1
      this.getAttachFileInformation()
    }
    // new-------------------------------    
  },
  watch: {
    'cancelCount': function(val) {
      if (val === this.dataList.length) {
        this.init()
        this.cancelCount = 0
        if(this.loading) {
          this.loading.close()
        }
      }
    },
    'deleteCount': function(val) {
      if (val === this.dataList.length) {
        this.init()
        this.deleteCount = 0
        if(this.loading) {
          this.loading.close()
        }
      }
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="less" scoped>
.zip-upload-box{
  width: 100%;
  height: 185px;
  text-align: center;
  padding-top: 60px;
  box-sizing: border-box;
  border: 1px solid #EBEEF5;
  i{
    display: block;
    margin: 0 auto;
    width: 42px;
    height: 42px;
    background: url(../../assets/img/icon-zip.png) no-repeat center center;
    background-size: 100%;
  }
  p{
    font-size: 12px;
    color: #5B5B5B;
    font-weight: 400;
    margin-top: 13px;
  }
}
</style>